// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "x_sB d_bz";
export var storyRowWrapper = "x_hv d_hv d_bG";
export var storyLeftWrapper = "x_sC d_bw d_bL";
export var storyWrapperFull = "x_sD d_cz";
export var storyWrapperFullLeft = "x_nQ d_cz d_bw d_bL d_bz";
export var contentWrapper = "x_mC d_hw";
export var storyLeftWrapperCenter = "x_sF d_hx d_bw d_bz d_bL d_bF";
export var storyRightWrapperCenter = "x_sG d_hC";
export var storyHeader = "x_sH d_hB d_s d_cp";
export var storyHeaderCenter = "x_hz d_hz d_s d_cp d_ds d_bv";
export var storyParagraphCenter = "x_hy d_hy d_bv d_ds";
export var storyBtnWrapper = "x_sJ d_dZ d_dY d_s d_bw d_bC";
export var storyBtnWrapperCenter = "x_hG d_hG d_dY d_s d_bw d_bz";
export var imageWrapper = "x_sw d_fc d_W";
export var imageWrapperFull = "x_sK d_s d_D d_bb d_W";