// extracted by mini-css-extract-plugin
export var tileContent = "t_rl d_s d_D d_W";
export var teamTextLeft = "t_rm d_dr";
export var teamTextCenter = "t_rn d_ds";
export var teamTextRight = "t_rp d_dt";
export var alignLeft = "t_qd d_s d_bw d_fl d_bC d_dr";
export var alignCenter = "t_bL d_s d_bw d_fm d_bz d_ds";
export var alignRight = "t_qf d_s d_bw d_fn d_bD d_dt";
export var teamContainer = "t_rq d_dS";
export var teamContainerFull = "t_rr d_dQ";
export var teamRowWrapper = "t_rs d_b8";
export var teamTileWrapper = "t_j1 d_j1 d_W d_cr";
export var teamTileSquareWrapper = "t_rt d_j2 d_W d_cr";
export var teamTileRoundWrapper = "t_j2 d_j2 d_W d_cr";
export var teamTileNoGuttersWrapper = "t_j3 d_j3 d_W";
export var teamHoverNoGutters = "t_j4 d_j4 d_X d_s d_D d_bw d_bz d_bL d_bj";
export var teamImageText = "t_j9 d_j9 d_bh d_s d_bv";
export var teamInfoWrapperSquare = "t_rv d_j5";
export var teamInfoWrapperRound = "t_j5 d_j5";
export var teamInfoWrapper = "t_rw d_j6 d_X";
export var teamInfoWrapperNoGutters = "t_j7 d_j7 d_s d_D d_bz d_bJ d_bF d_c4";
export var teamImgWrapper = "t_jX d_jX";
export var teamImgWrapperAlt = "t_jY d_jY";
export var teamImgWrapperNoGutters = "t_j8 d_j8";
export var teamHeader = "t_rx d_cs";
export var teamHeaderAlt = "t_ry d_cs";
export var teamHeaderNoGutters = "t_rz d_cs d_cz";