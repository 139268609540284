// extracted by mini-css-extract-plugin
export var noPadding = "z_ff d_ff";
export var videoFrameWrapper = "z_sX d_kW d_b2";
export var videoIframeStyle = "z_pH d_d2 d_s d_D d_bv d_bY d_N";
export var videoLayoutRight = "z_sY d_kV d_bG";
export var videoInnerWrapper = "z_sZ d_d8 d_d7 d_L d_W d_bw d_bz d_bL d_b2";
export var videoText = "z_s0 d_ds d_s";
export var videoInnerInnerWrapper = "z_s1 d_bw d_bz d_bF";
export var btnWrapper = "z_dZ d_s d_bw d_bz d_cf";
export var btn = "z_mD d_bY d_bw d_bz d_bL d_cD d_b3 d_dx d_k B_tK B_tw";
export var icon = "z_p0 d_cy";