// extracted by mini-css-extract-plugin
export var alignLeft = "y_qd d_fl d_bC d_dr";
export var alignCenter = "y_bL d_fm d_bz d_ds";
export var alignRight = "y_qf d_fn d_bD d_dt";
export var testimonialsContainer = "y_sL d_dS";
export var testimonialsContainerFull = "y_sM d_dQ";
export var testimonialsMainHeader = "y_jt d_jt d_s d_cs";
export var testimonialsComponentParagraph = "y_jw d_jw";
export var testimonialsComponentSmall = "y_jv d_jv";
export var testimonialsComponentsWrapper = "y_jq d_jq d_v d_s d_cr d_b0 d_b2";
export var testimonialsComponentsWrapperDesign2 = "y_jr d_jr d_v d_s d_cr d_b0 d_b2 d_by";
export var testimonialsComponentsWrapperDesign3 = "y_js d_js d_v d_s d_D d_b2 d_bQ d_bw d_bF d_bL d_bz";
export var testimonialsBackgroundColor = "y_sN d_d4";
export var colEqualHeight = "y_sP d_bw";
export var testimonialsColumnWrapper = "y_jx d_jx d_b0";
export var testimonialsImageWrapper = "y_jm d_jm d_s d_W";
export var testimonialsImageWrapperDesign2 = "y_jn d_jn d_n d_W d_bt";
export var testimonialsImageWrapperDesign3 = "y_jp d_jp d_W d_bw";
export var design2TextWrapper = "y_sQ d_by d_bL d_bF";
export var design3 = "y_sR d_cz d_D";
export var imageDesign2 = "y_sS";
export var SmallSmall = "y_sT B_sT B_s2 B_tp";
export var SmallNormal = "y_sV B_sV B_s2 B_tq";
export var SmallLarge = "y_sW B_sW B_s2 B_tm";
export var exceptionWeight = "y_qW B_tt";