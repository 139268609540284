// extracted by mini-css-extract-plugin
export var alignDiscLeft = "q_qr d_fl d_bC d_dr";
export var alignLeft = "q_qd d_fl d_bC d_dr";
export var alignDiscCenter = "q_qs d_fm d_bz d_ds";
export var alignCenter = "q_bL d_fm d_bz d_ds";
export var alignDiscRight = "q_qt d_fn d_bD d_dt";
export var alignRight = "q_qf d_fn d_bD d_dt";
export var footerContainer = "q_qv d_dS d_bS";
export var footerContainerFull = "q_qw d_dQ d_bS";
export var footerHeader = "q_kc d_kc";
export var footerTextWrapper = "q_qx d_s";
export var footerDisclaimerWrapper = "q_kk d_kk d_cg";
export var badgeWrapper = "q_qy d_s d_bw d_bL d_bB d_bF";
export var footerDisclaimerRight = "q_kl d_kl d_bw";
export var footerDisclaimerLeft = "q_km d_km d_bw";
export var verticalTop = "q_qz d_bw d_bF d_bK d_bH";
export var firstWide = "q_qB";
export var disclaimer = "q_qC";
export var socialDisclaimer = "q_qD";
export var left = "q_qF";
export var wide = "q_qG d_cv";
export var right = "q_qH d_bG";
export var line = "q_fg d_fh d_cr";
export var badgeDisclaimer = "q_qJ d_by d_bL d_bF";
export var badgeContainer = "q_qK d_bw d_bD d_bL";
export var badge = "q_qL";
export var padding = "q_qM d_c4";
export var end = "q_qN d_bD";
export var linkWrapper = "q_qP d_dx";
export var link = "q_mF d_dx";
export var colWrapper = "q_qQ d_ct";
export var media = "q_qR d_bt d_dv";
export var itemRight = "q_qS";
export var itemLeft = "q_qT";
export var itemCenter = "q_qV";
export var exceptionWeight = "q_qW B_tt";