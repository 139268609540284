// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "r_qX d_gs d_cp";
export var heroHeaderCenter = "r_gt d_gt d_cp d_ds";
export var heroHeaderRight = "r_gv d_gv d_cp d_dt";
export var heroParagraphLeft = "r_qY d_gp d_cs";
export var heroParagraphCenter = "r_gq d_gq d_cs d_ds";
export var heroParagraphRight = "r_gr d_gr d_cs d_dt";
export var heroBtnWrapperLeft = "r_qZ d_dZ d_dY d_s d_bw d_bC";
export var heroBtnWrapperCenter = "r_q0 d_d0 d_dY d_s d_bw d_bz";
export var heroBtnWrapperRight = "r_q1 d_d1 d_dY d_s d_bw d_bD";
export var overlayBtnWrapper = "r_q2 d_gn d_X d_4 d_5 d_6 d_bk d_b8";
export var design4 = "r_q3 d_gm d_X d_4 d_5 d_bk";
export var heroExceptionSmall = "r_q4 B_q4";
export var heroExceptionNormal = "r_q5 B_q5";
export var heroExceptionLarge = "r_q6 B_q6";
export var Title1Small = "r_q7 B_q7 B_s2 B_s3";
export var Title1Normal = "r_q8 B_q8 B_s2 B_s4";
export var Title1Large = "r_q9 B_q9 B_s2 B_s5";
export var BodySmall = "r_rb B_rb B_s2 B_tl";
export var BodyNormal = "r_rc B_rc B_s2 B_tm";
export var BodyLarge = "r_rd B_rd B_s2 B_tn";