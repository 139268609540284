// extracted by mini-css-extract-plugin
export var carouselContainer = "G_vC d_s d_D d_bb d_W";
export var carouselContainerCards = "G_vD G_vC d_s d_D d_bb d_W";
export var carouselContainerSides = "G_vF d_s d_D d_W";
export var prevCarouselItem = "G_vG d_s d_D d_X d_g";
export var prevCarouselItemL = "G_vH G_vG d_s d_D d_X d_g";
export var moveInFromLeft = "G_vJ";
export var prevCarouselItemR = "G_vK G_vG d_s d_D d_X d_g";
export var moveInFromRight = "G_vL";
export var selectedCarouselItem = "G_vM d_s d_D d_W d_bb";
export var selectedCarouselItemL = "G_vN G_vM d_s d_D d_W d_bb";
export var selectedCarouselItemR = "G_vP G_vM d_s d_D d_W d_bb";
export var nextCarouselItem = "G_vQ d_s d_D d_X d_g";
export var nextCarouselItemL = "G_vR G_vQ d_s d_D d_X d_g";
export var nextCarouselItemR = "G_vS G_vQ d_s d_D d_X d_g";
export var arrowContainer = "G_vT d_D d_X d_dx d_bh d_1 d_bY";
export var prevArrowContainer = "G_vV G_vT d_D d_X d_dx d_bh d_1 d_bY";
export var prevArrowContainerHidden = "G_vW G_vV G_vT d_D d_X d_dx d_bh d_1 d_bY d_g";
export var nextArrowContainer = "G_vX G_vT d_D d_X d_dx d_bh d_1 d_bY";
export var carouselContainerProducts = "G_vY";
export var nextArrowContainerHidden = "G_vZ G_vX G_vT d_D d_X d_dx d_bh d_1 d_bY d_g";
export var arrow = "G_kK d_X";
export var prevArrow = "G_v0 G_kK d_X";
export var nextArrow = "G_v1 G_kK d_X";
export var carouselIndicatorContainer = "G_v2 d_s d_ds d_bh d_bw d_bz d_bL d_bF d_X";
export var btnsContainer = "G_v3 d_s d_bw d_bB";
export var carouselText = "G_v4 d_dm d_bw d_bz d_bL d_bY d_dx d_cz";
export var carouselTextInactive = "G_v5 G_v4 d_dm d_bw d_bz d_bL d_bY d_dx d_cz";
export var indicator = "G_v6 d_b4";
export var carouselIndicator = "G_v7 G_v6 d_b4";
export var carouselIndicatorSelected = "G_v8 G_v6 d_b4";
export var arrowsContainerTopRight = "G_v9 d_X d_bh d_by";
export var arrowsContainerBottomLeft = "G_wb d_X d_bh d_by";
export var arrowsContainerSides = "G_wc d_X d_bh d_by";
export var smallArrowsBase = "G_wd d_bQ d_bw d_bz d_bL d_bY d_dx d_cz";
export var smallArrowContainer = "G_wf G_wd d_bQ d_bw d_bz d_bL d_bY d_dx d_cz";
export var smallArrowContainerPrev = "G_wg G_wf G_wd d_bQ d_bw d_bz d_bL d_bY d_dx d_cz";
export var smallArrowContainerSmall = "G_wh G_wd d_bQ d_bw d_bz d_bL d_bY d_dx d_cz";
export var smallArrowContainerPrevSmall = "G_wj G_wh G_wd d_bQ d_bw d_bz d_bL d_bY d_dx d_cz";
export var icon = "G_p0";
export var iconSmall = "G_wk";
export var multipleWrapper = "G_wl d_by d_bB d_bb";
export var multipleImage = "G_wm d_by";
export var sidesPrevContainer = "G_wn G_wf G_wd d_bQ d_bw d_bz d_bL d_bY d_dx d_cz d_fk d_X d_bh d_cz";
export var sidesNextContainer = "G_wp G_wf G_wd d_bQ d_bw d_bz d_bL d_bY d_dx d_cz d_fk d_X d_bh d_cz";